import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
export default class CustomSpinner extends Component {
    render() {
        return (
            <div className="text-center">
                <Spinner type="grow" color="danger" />
                <Spinner type="grow" color="primary" />
                <Spinner type="grow" color="danger" />
            </div>
        )
    }
}